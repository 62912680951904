import React from "react"
import "../styles/styles.scss"


//Import Components
import Header from "../components/header"
import Footer from "../components/footer"



const CaseManagementSystemFull = () => (
  <div>
    <Header/>
    <Footer />
  </div>
)

export default CaseManagementSystemFull